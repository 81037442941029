import { request } from '@@/plugin-request';

export async function putSchool(params) {
  return request('/api/school', {
    method: 'PUT',
    data: params,
  });
}

export async function update(payload) {
  return request('/api/school/enable', {
    method: 'PUT',
    data: payload,
  });
}

export async function query(params) {
  return request('/api/school/query', {
    method: 'GET',
    params,
  });
}

export async function getCountries(params) {
  return request('/api/school/country', {
    method: 'GET',
    params,
  });
}

export async function querySemester(params) {
  return request('/api/semester/query', {
    method: 'GET',
    params,
  });
}

export async function createPattern(payload) {
  return request('/api/semester/createPattern', {
    method: 'POST',
    data: payload,
  });
}

export async function autoCreateForSchool(payload) {
  return request('/api/semester/autoCreateForSchool', {
    method: 'POST',
    data: payload,
  });
}

export async function updateSemesterPatternsName(payload) {
  return request('/api/semester/updateSemesterPatternsName', {
    method: 'PUT',
    data: payload,
  });
}

export async function createSchoolEvents(payload) {
  return request('/api/school/event', {
    method: 'POST',
    data: payload,
  });
}

export async function querySchoolEvents(params) {
  return request('/api/school/event', {
    method: 'GET',
    params,
  });
}

export async function updateSchoolEvent(payload) {
  return request('/api/school/event', {
    method: 'PUT',
    data: payload,
  });
}

export async function deleteSchoolEvent(params) {
  return request('/api/school/event', {
    method: 'DELETE',
    params,
  });
}
